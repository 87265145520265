import { ReactComponent as AppleJoystickLineal } from "./lineal/1472-apple-arcade-joistick-lineal.svg";
import { ReactComponent as BestSellerLineal } from "./lineal/1368-best-seller-lineal.svg";
import { ReactComponent as BrushLineal } from "./lineal/118-brush-lineal.svg";
import { ReactComponent as CameraLineal } from "./lineal/61-camera-lineal.svg";
import { ReactComponent as ConfettiLineal } from "./lineal/1103-confetti-lineal.svg";
import { ReactComponent as CloakroomLineal } from "./lineal/1721-cloakroom-lineal.svg";
import { ReactComponent as CrownLineal } from "./lineal/407-crown-king-lord-lineal.svg";
import { ReactComponent as Dog_WalkingLineal } from "./lineal/1164-dog-walking-lineal.svg";
import { ReactComponent as ExampleLineal } from "./lineal/1433-example-lineal.svg";
import { ReactComponent as F1_CarLineal } from "./lineal/890-f-1-car-lineal.svg";
import { ReactComponent as FootballLineal } from "./lineal/431-football-soccer-ball-lineal.svg";
import { ReactComponent as HairBrushLineal } from "./lineal/1591-hair-brush-lineal.svg";
import { ReactComponent as HairDryerLineal } from "./lineal/1570-hair-dryer-lineal.svg";
import { ReactComponent as HandGiftLineal } from "./lineal/2968-hand-gift-lineal.svg";
import { ReactComponent as HammerLineal } from "./lineal/1733-hammer-lineal.svg";
import { ReactComponent as HeartLineal } from "./lineal/20-love-heart-lineal.svg";
import { ReactComponent as Ice_CreamLineal } from "./lineal/549-ice-cream-scoops-lineal.svg";
import { ReactComponent as MakeupLineal } from "./lineal/1598-makeup-lineal.svg";
import { ReactComponent as MargaritaLineal } from "./lineal/2993-margarita-glass-lineal.svg";
import { ReactComponent as MedicalLineal } from "./lineal/1267-medical-insurancea-lineal.svg";
import { ReactComponent as Nail_PolishLineal } from "./lineal/1592-nail-polish-lineal.svg";
import { ReactComponent as PedicureLineal } from "./lineal/1571-pedicure-lineal.svg";
import { ReactComponent as PizzaLineal } from "./lineal/13-pizza-lineal.svg";
import { ReactComponent as ScissorsIconLineal } from "./lineal/114-scissors-lineal.svg";
import { ReactComponent as ScissorsLineal } from "./lineal/1569-barber-scissors-lineal.svg";
import { ReactComponent as SkatesLineal } from "./lineal/2943-skates-lineal.svg";
import { ReactComponent as SparklesLineal } from "./lineal/2474-sparkles-glitter-lineal.svg";
import { ReactComponent as StrawberryLineal } from "./lineal/578-strawberry-lineal.svg";
import { ReactComponent as TeddyBearLineal } from "./lineal/1546-teddy-bear-toy-lineal.svg";
import { ReactComponent as TrebleClefLineal } from "./lineal/1497-treble-clef-lineal.svg";
import { ReactComponent as UmbrellaLineal } from "./lineal/422-umbrella-lineal.svg";
import { ReactComponent as TrottingHorseLineal } from "./lineal/1169-trotting-horse-lineal.svg";
import { ReactComponent as BarberScissorsLineal } from "./lineal/1569-barber-scissors-lineal.svg";
import { ReactComponent as SpaFlowerLineal } from "./lineal/1574-spa-flower-lineal.svg";
import { ReactComponent as PingPongTableTennisLineal } from "./lineal/1810-ping-pong-table-tennis-lineal.svg";
import { ReactComponent as GolfLineal } from "./lineal/1815-golf-lineal.svg";
import { ReactComponent as PaintballGunLineal } from "./lineal/1900-paintball-gun-lineal.svg";
import { ReactComponent as BeveragesLineal } from "./lineal/1910-beverages-lineal.svg";
import { ReactComponent as BurgerLineal } from "./lineal/1916-burger-alt-lineal.svg";
import { ReactComponent as StarRatingLineal } from "./lineal/237-star-rating-lineal.svg";
import { ReactComponent as BowlOfSoupLineal } from "./lineal/2372-bowl-of-soup-lineal.svg";
import { ReactComponent as CoffeeTakeAwayLineal } from "./lineal/238-coffee-take-away-lineal.svg";
import { ReactComponent as CoffeeLineal } from "./lineal/239-coffee-lineal.svg";
import { ReactComponent as MagicWandLineal } from "./lineal/2844-magic-wand-lineal.svg";
import { ReactComponent as CupcakeHeartLineal } from "./lineal/3023-cupcake-heart-lineal.svg";
import { ReactComponent as StudyGraduationLineal } from "./lineal/406-study-graduation-lineal.svg";
import { ReactComponent as GiftLineal } from "./lineal/412-gift-lineal.svg";
import { ReactComponent as WeightGymFitnessLineal } from "./lineal/429-weight-gym-fitness-lineal.svg";
import { ReactComponent as BasketballBallLineal } from "./lineal/432-basketball-ball-lineal.svg";
import { ReactComponent as PawsAnimalLineal } from "./lineal/448-paws-animal-lineal.svg";
import { ReactComponent as StampLineal } from "./lineal/460-stamp-lineal.svg";
import { ReactComponent as ComputerDisplayLineal } from "./lineal/478-computer-display-lineal.svg";
import { ReactComponent as CoffeeBeansLineal } from "./lineal/622-coffee-beans-lineal.svg";
import { ReactComponent as MaleDoctorCareLineal } from "./lineal/671-male-doctor-care-lineal.svg";

// ------------------- Wired SVG Imports -------------------

import { ReactComponent as AppleJoystickWired } from "./wired/1472-apple-arcade-joistick.svg";
import { ReactComponent as BasketballWired } from "./wired/432-basketball-ball.svg";
import { ReactComponent as BestSellerWired } from "./wired/1368-best-seller.svg";
import { ReactComponent as BurgerWired } from "./wired/1916-burger-alt.svg";
import { ReactComponent as CameraWired } from "./wired/61-camera.svg";
import { ReactComponent as CoffeeBeansWired } from "./wired/622-coffee-beans.svg";
import { ReactComponent as CoffeeCupWired } from "./wired/238-coffee-take-away.svg";
import { ReactComponent as ConfettiWired } from "./wired/1103-confetti.svg";
import { ReactComponent as Dog_WalkingWired } from "./wired/1164-dog-walking.svg";
import { ReactComponent as EnvironmentWired } from "./wired/437-environment-eco-care.svg";
import { ReactComponent as F1_CarWired } from "./wired/890-f-1-car.svg";
import { ReactComponent as FishWired } from "./wired/522-fish.svg";
import { ReactComponent as FootballWired } from "./wired/431-football-soccer-ball.svg";
import { ReactComponent as GiftWired } from "./wired/412-gift.svg";
import { ReactComponent as GolfWired } from "./wired/1815-golf.svg";
import { ReactComponent as HammerWired } from "./wired/1733-hammer.svg";
import { ReactComponent as HandGiftWired } from "./wired/2968-hand-gift.svg";
import { ReactComponent as Ice_CreamWired } from "./wired/549-ice-cream-scoops.svg";
import { ReactComponent as MakeupWired } from "./wired/1598-makeup.svg";
import { ReactComponent as MargaritaWired } from "./wired/2993-margarita-glass.svg";
import { ReactComponent as MonitorWired } from "./wired/478-computer-display.svg";
import { ReactComponent as Nail_PolishWired } from "./wired/1592-nail-polish.svg";
import { ReactComponent as PaintballWired } from "./wired/1900-paintball-gun.svg";
import { ReactComponent as PedicureWired } from "./wired/1571-pedicure.svg";
import { ReactComponent as PingPongWired } from "./wired/1810-ping-pong-table-tennis.svg";
import { ReactComponent as PizzaWired } from "./wired/13-pizza.svg";
import { ReactComponent as ScissorsWired } from "./wired/114-scissors.svg";
import { ReactComponent as SkatesWired } from "./wired/2943-skates.svg";
import { ReactComponent as Smartphone_Wired } from "./wired/2805-smartphone-2.svg";
import { ReactComponent as StarsWired } from "./wired/237-star-rating.svg";
import { ReactComponent as GraduationWired } from "./wired/406-study-graduation.svg";
import { ReactComponent as TeddyBearWired } from "./wired/1546-teddy-bear-toy.svg";
import { ReactComponent as TrebleClefWired } from "./wired/1497-treble-clef.svg";
import { ReactComponent as HorseWired } from "./wired/1169-trotting-horse.svg";
import { ReactComponent as UmbrellaWired } from "./wired/422-umbrella.svg";
import { ReactComponent as GymWeightWired } from "./wired/429-weight-gym-fitness.svg";
import { ReactComponent as HeartWired } from "./wired/20-love-heart.svg";
import { ReactComponent as ExampleWired } from "./wired/1433-example.svg";
import { ReactComponent as SparklesWired } from "./wired/2474-sparkles-glitter.svg";
import { ReactComponent as StrawberryWired } from "./wired/578-strawberry.svg";
import { ReactComponent as BarberScissorsWired } from "./wired/1569-barber-scissors.svg";
import { ReactComponent as BrushWired } from "./wired/118-brush.svg";
import { ReactComponent as CupcakeHeartWired } from "./wired/3023-cupcake-heart.svg";
import { ReactComponent as EnvironmentEcoCareWired } from "./wired/437-environment-eco-care.svg";
import { ReactComponent as HairBrushWired } from "./wired/1591-hair-brush.svg";
import { ReactComponent as HairDryerWired } from "./wired/1570-hair-dryer.svg";
import { ReactComponent as MagicWandWired } from "./wired/2844-magic-wand.svg";
import { ReactComponent as MaleDoctorCareWired } from "./wired/671-male-doctor-care.svg";
import { ReactComponent as MedicalInsuranceWired } from "./wired/1267-medical-insurancea.svg";

export const stamps = {
  AppleJoystickLineal,
  BestSellerLineal,
  BrushLineal,
  CameraLineal,
  ConfettiLineal,
  CloakroomLineal,
  CrownLineal,
  Dog_WalkingLineal,
  ExampleLineal,
  F1_CarLineal,
  FootballLineal,
  HairBrushLineal,
  HairDryerLineal,
  HandGiftLineal,
  HammerLineal,
  HeartLineal,
  Ice_CreamLineal,
  MakeupLineal,
  MargaritaLineal,
  MedicalLineal,
  Nail_PolishLineal,
  PedicureLineal,
  PizzaLineal,
  ScissorsIconLineal,
  ScissorsLineal,
  SkatesLineal,
  SparklesLineal,
  StrawberryLineal,
  TeddyBearLineal,
  TrebleClefLineal,
  UmbrellaLineal,
  TrottingHorseLineal,
  BarberScissorsLineal,
  SpaFlowerLineal,
  PingPongTableTennisLineal,
  GolfLineal,
  PaintballGunLineal,
  BeveragesLineal,
  BurgerLineal,
  StarRatingLineal,
  BowlOfSoupLineal,
  CoffeeTakeAwayLineal,
  CoffeeLineal,
  MagicWandLineal,
  CupcakeHeartLineal,
  StudyGraduationLineal,
  GiftLineal,
  WeightGymFitnessLineal,
  BasketballBallLineal,
  PawsAnimalLineal,
  StampLineal,
  ComputerDisplayLineal,
  CoffeeBeansLineal,
  MaleDoctorCareLineal,
  AppleJoystickWired,
  BasketballWired,
  BestSellerWired,
  BurgerWired,
  CameraWired,
  CoffeeBeansWired,
  CoffeeCupWired,
  ConfettiWired,
  Dog_WalkingWired,
  EnvironmentWired,
  F1_CarWired,
  FishWired,
  FootballWired,
  GiftWired,
  GolfWired,
  HammerWired,
  HandGiftWired,
  Ice_CreamWired,
  MakeupWired,
  MargaritaWired,
  MonitorWired,
  Nail_PolishWired,
  PaintballWired,
  PedicureWired,
  PingPongWired,
  PizzaWired,
  ScissorsWired,
  SkatesWired,
  Smartphone_Wired,
  StarsWired,
  GraduationWired,
  TeddyBearWired,
  TrebleClefWired,
  HorseWired,
  UmbrellaWired,
  GymWeightWired,
  HeartWired,
  ExampleWired,
  SparklesWired,
  Ice_CreamWired,
  StrawberryWired,
  BarberScissorsWired, // added
  BrushWired, // added
  CupcakeHeartWired, // added
  HairBrushWired, // added
  HairDryerWired, // added
  MagicWandWired, // added
  MaleDoctorCareWired, // added
  MedicalInsuranceWired, // added
  PedicureWired, // added
};
